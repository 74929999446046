import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0px; 
`;

export const Contents = styled.div`
  margin-right: 7%;
  margin-left: 7%; 
`;

export const Map = styled.div`
  img {
    max-height: 1024px;
    width: 100%;
    object-fit: cover;
    border-radius: 0px 0px 0px 0px ;
} 
`;